import contract from "./PeakyBirds.json";
import abi from "./PeakahEggs";

export const network = {
  SMARTCONTRACT: {
    TESTNET: "0x4724f601c6b9814dCD757B74C8fCeb3223629c99",
    MAINNET: "0x40218553d2838Bab498dc23bd6560d14dE113802",
    ABI: contract.abi,
  },
  PEAKYCONTRACT: {
    TESTNET: "0x3db76A9a09362f005a31f9150D3974583da7c2AB",
    MAINNET: "0xa4eC20e055eA3E43CD5CAC29d4f8c7b2F24C96fB",
    ABI: abi,
  },
  MAINNET: {
    NAME: "Ethereum",
    SYMBOL: "ETH",
    ID: 1,
  },
  TESTNET: {
    NAME: "Goerli",
    SYMBOL: "GoerliETH",
    ID: 5,
  },
};

export default { network };
