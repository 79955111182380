export const Icon = ({
    classes = "text-peakahGreen",
    height = "24",
    width = "24",
    children = {},
    fill = "none",
    stroke = "currentColor",
    strokeWidth = "2",
    strokeLinecap = "round",
    strokeLinejoin = "round",
    viewBox = "0 0 24 24",
  }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap={strokeLinecap}
      strokeLinejoin={strokeLinejoin}
    >
      {children}
    </svg>
  );
  