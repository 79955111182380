const NavBarElements = [
  {
    name: "Discord",
    link: "https://discord.gg/peakybirds",
  },
  {
    name: "Twitter",
    link: "https://twitter.com/peaky_birds",
  },
  {
    name: "Litepaper",
    link: "https://magic-cup-709.notion.site/Peaky-Birds-Litepaper-38031e84c2dd48c8ad77c8a786f208f7",
  },
  {
    name: "Peakah",
    link: "https://www.peakah.com",
  },
];

export default NavBarElements;
