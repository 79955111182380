import React, { useState, useEffect } from "react";
import { network } from "../contract/config";
import { ethers, parseEther, formatUnits } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import backgroundImage from "../assets/background.png";
import peakahIslandImage from "../assets/peakahIsland.png";
import { ControlButton } from "../components/ControlButton/ControlButton";

const MintPage = (props) => {
  const gradientImage = `linear-gradient(rgba(0, 0, 0, -0.1), rgba(0, 0, 0, 0) 90%), url(${backgroundImage}`;
  const { ethereum } = window;
  const [provider, setProvider] = useState(null);
  const [contract, setContract] = useState(null);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [buttonText, setButtonText] = useState("");
  const [tokenAmount, setTokenAmount] = useState(1);
  const [hasMinted, setHasMinted] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState(false);
  const [costForFirstToken, setCostForFirstToken] = useState(0);
  const [costForSubsequentTokens, setCostForSubsequentTokens] = useState(0);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Setting up contract and provider
  const initializeProvider = async () => {
    const isLocked = !(await ethereum._metamask.isUnlocked());
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (metamaskIsInstalled && !isLocked) {
      const provider = new Web3Provider(ethereum);
      const signer = await provider.getSigner();

      // Set up network
      if (metamaskIsInstalled) {
        try {
          const accounts = await ethereum.request({ method: "eth_accounts" });
          const chainIdHex = await ethereum.request({ method: "eth_chainId" });
          const networkId = parseInt(chainIdHex, 16).toString();

          if (networkId === network.MAINNET.ID.toString()) {
            const nftContract = new ethers.Contract(
              network.SMARTCONTRACT.MAINNET,
              network.SMARTCONTRACT.ABI,
              signer
            );

            setCurrentAccount(accounts[0]);
            setContract(nftContract);
            setProvider(provider);
          } else {
            setButtonText(`Please switch to the ${network.MAINNET.NAME} network`);
          }
          setCurrentNetwork(networkId);
        } catch (err) {
          console.log(err);
        }
      } else {
        setButtonText(`Please install Metamask`);
      }
    }
  };

  // Minting tokens
  const mintTokens = async () => {
    try {
      if (currentNetwork === network.MAINNET.ID.toString()) {
        let parsedAmount;
        if (!hasMinted && tokenAmount > 1) {
          parsedAmount = costForFirstToken + costForSubsequentTokens * (tokenAmount - 1);
        } else if (!hasMinted && tokenAmount === 1) {
          parsedAmount = costForFirstToken;
        } else {
          parsedAmount = costForSubsequentTokens * tokenAmount;
        }

        const tx = await contract.safeMint(tokenAmount, {
          value: parseEther(parsedAmount.toFixed(3).toString()),
        });

        console.log("Minting in progress...");
        await tx.wait();
        console.log("Tokens minted successfully!");
      }
    } catch (error) {
      console.error("Error minting tokens:", error);
    // Extracting the error message
    const message = error.data?.message || "An error occurred while minting tokens.";
    setErrorMessage(message);
    setShowError(true);
  }
  };

    // Function to close the error popup
    const closeErrorPopup = () => {
      setShowError(false);
    };
  
    // Effect to fade out the error popup after 5 seconds
    useEffect(() => {
      if (showError) {
        const timer = setTimeout(() => {
          setShowError(false);
        }, 5000);
  
        return () => clearTimeout(timer);
      }
    }, [showError]);

  // Counter
  function incrementCount() {
    if (tokenAmount < 3) setTokenAmount(tokenAmount + 1);
  }
  function decrementCount() {
    if (tokenAmount > 1) setTokenAmount(tokenAmount - 1);
  }

  // Reloading window when chain or account is changed
  useEffect(() => {
    if (ethereum) {
      ethereum.on("chainChanged", () => {
        window.location.reload();
      });
      ethereum.on("accountsChanged", () => {
        window.location.reload();
      });
    }
  }, []);

  // Initialize Provider
  useEffect(() => {
    initializeProvider();
  }, []);

  // Check account minting status and retrieve costs
  useEffect(() => {
    const checkMintingStatusAndCosts = async () => {
      if (contract) {
        const hasMintedBefore = await contract.hasMinted(currentAccount);
        setHasMinted(hasMintedBefore);

        const firstTokenCost = await contract.getCostForFirstToken();
        const subsequentTokensCost = await contract.getCostForSubsequentTokens();

        setCostForFirstToken(parseFloat(formatUnits(firstTokenCost)));
        setCostForSubsequentTokens(parseFloat(formatUnits(subsequentTokensCost)));
      }
    };

    checkMintingStatusAndCosts();
  }, [currentAccount, contract]);

  useEffect(() => {
    let totalPrice;
    if (!hasMinted && tokenAmount > 1) {
      totalPrice = costForFirstToken + costForSubsequentTokens * (tokenAmount - 1);
    } else if (!hasMinted && tokenAmount === 1) {
      totalPrice = costForFirstToken;
    } else {
      totalPrice = costForSubsequentTokens * tokenAmount;
    }

    setButtonText(`${totalPrice.toFixed(3)} ${network.MAINNET.SYMBOL} - Mint Now!`);
  }, [hasMinted, tokenAmount, costForFirstToken, costForSubsequentTokens]);

  return (
    <div
      className="flex flex-col items-center relative bg-cover bg-no-repeat bg-center w-screen h-screen px-3 sm:px-10 lg:px-10 scroll-smooth"
      style={{
        backgroundImage: gradientImage,
      }}
    >
      {/* Error Popup */}
      {showError && (
        <div
          className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 transition-opacity duration-500"
          style={{ opacity: showError ? 1 : 0 }}
        >
          <div className="bg-white p-4 rounded-lg shadow-lg relative">
            <button onClick={closeErrorPopup} className="absolute top-2 right-2 text-red-500 hover:text-red-700">
              &times;
            </button>
            <p className="text-red-500">{errorMessage}</p>
          </div>
        </div>
      )}

      <div className="flex justify-center">
        <img
          className="object-right object-scale-down w-full h-screen pt-0 2xl:pt-32 p-0 lg:p-10"
          src={peakahIslandImage}
          alt="Peaky Birds Island"
        />
      </div>

      <div className="flex justify-center">
        <div className="absolute bottom-20 md:bottom-44 lg:bottom-44">
          {contract && (
            <div className="flex flex-row mb-10">
              <ControlButton direction="left" clicked={decrementCount} />
              <div className="w-14 h-14 rounded-full bg-brightBlue shadow-straight flex items-center justify-center text-white">
                {tokenAmount}
              </div>
              <ControlButton direction="right" clicked={incrementCount} />
            </div>
          )}
          <div className="p-3 lg:p-5 px-5 bg-brightBlue rounded-3xl text- md:text-2xl text-center text-white font-medium transition ease-in-out hover:-translate-y-5 hover:duration-700 duration-500 hover:bg-opacity-80">
            <button onClick={mintTokens}>
              <div>{buttonText.split(' - ')[0]}</div>
              <div>{buttonText.split(' - ')[1]}</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintPage;