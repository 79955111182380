interface NavBarButtonProps {
  buttonText: React.ReactNode;
  buttonIcon?: React.ReactNode;
  // buttonStyle?: NavBarButtonStyle;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  className?: string;
  isDropdown?: boolean;
  isButtonUp?: boolean;
}

const NavBarButton: React.FC<NavBarButtonProps> = (props) => {
  const { buttonText, buttonIcon, onClick, disabled, className } = props;

  let isDisabled = disabled ?? false;

  return (
    <>
      <div
        className={`${className} block text-white rounded p-3 border rounded-lg font-medium`}
        onClick={isDisabled ? () => {} : onClick}
      >
        <div className={""}>
          {buttonIcon && <div className={""}>{buttonIcon}</div>}
          <div>{buttonText}</div>
          {/* {props.isDropdown && (
              <div
                className={
                  props.isButtonUp
                    ? navDropdownClasses.arrowUp
                    : navDropdownClasses.arrowDown
                }
              >
                <FontAwesomeIcon
                  icon={props.isButtonUp ? faSortUp : faSortDown}
                />{" "}
              </div>
            )} */}
        </div>
      </div>
    </>
  );
};

export default NavBarButton;
