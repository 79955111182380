import React from "react";
import { Route, Routes } from "react-router-dom";
import MintPage from "./pages/MintPage";
import Layout from "./pages/Layout";


function App() {
  return (
    <div className="App">
      <div className="flex flex-col max-h-screen w-screen">
      <Routes>
        <Route element={<Layout />}>
          <Route exact path="/" Component={MintPage} />
        </Route>
      </Routes>
      </div>
    </div>
  );
}

export default App;
