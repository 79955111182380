import React, { useEffect } from "react";
import { useState } from "react";
import NavBarButton from "../NavBarButton/NavBarButton";

export const NavWallet = (props) => {
  // const { address } = props;
  const { ethereum } = window;
  const [currentAccount, setCurrentAccount] = useState(null);
  const [buttonText, setButtonText] = useState("Connect");

  const connectWalletHandler = async () => {
    const isLocked = !(await ethereum._metamask.isUnlocked());
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (currentAccount && metamaskIsInstalled && !isLocked) {
      // Prompting the user to disconnect if already connected
      const disconnect = window.confirm("Do you want to disconnect your wallet?");
      if (disconnect) {
        // Logic to disconnect the wallet (this may vary depending on the library you are using)
        setCurrentAccount(null);
      }
    } else if (metamaskIsInstalled && !isLocked) {
      try {
        const accounts = await ethereum.request({
          method: "eth_requestAccounts",
        });
        setCurrentAccount(accounts[0]);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("Wallet: Metamask is not unlocked!");
    }
  };

  // Initialize Provider
  useEffect(() => {
    connectWalletHandler();
  }, []);

  // Changing button
  useEffect(() => {
    if (currentAccount) {
      setButtonText("Connected");
    } else {
      setButtonText("Connect");
    }
  }, [currentAccount]);

  return (
    <div className="" onClick={connectWalletHandler}>
      <NavBarButton
        buttonText={buttonText}
        className="hover:bg-opacity-80 bg-brightBlue"
      />
    </div>
  );
};

export default NavWallet;
