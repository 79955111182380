import { IconChevronLeft } from "../Icon/IconChevronLeft";
import { IconChevronRight } from "../Icon/IconChevronRight";

export const ControlButton = ({
  clicked,
  direction = "right",
}) => (
  <div
    onClick={() => clicked()}
    className={`${direction === "right" ? "translate-x-8 transform" : "-translate-x-8 transform"} 
    w-14 h-14 rounded-full bg-white shadow-straight flex items-center justify-center cursor-pointer hover:bg-gray-100 hover:shadow-lg`}
  >
    {direction === "right" ? (
      <IconChevronRight
        classes="text-blue"
        width={28}
        height={28}
        strokeWidth={2}
      />
    ) : (
      <IconChevronLeft
        classes="text-blue"
        width={28}
        height={28}
        strokeWidth={2}
      />
    )}
  </div>
);
