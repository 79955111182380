import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PreloadedState, configureStore, combineReducers } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { createRouterMiddleware, createRouterReducer } from '@lagunovsky/redux-react-router'; // Assuming you're using connected-react-router

import { History } from 'history';
import account from "./components/State/account";

const createRootReducer = (history: History) =>
  combineReducers({
    router: createRouterReducer(history),
    account,
  });
export const history = createBrowserHistory(); // Create browser history

export default function createStore(preloadedState:PreloadedState<any>) {
  const store = configureStore({
    reducer: createRootReducer(history),
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(createRouterMiddleware(history)),
    // ... other configurations ...
    devTools: true, // Enable Redux DevTools
  });

  return store;
}
const store = createStore({});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
