import React from "react";
import { NavBar } from "../components/Navbar/NavBar";
import {Outlet} from "react-router-dom";

export default function Layout() {
  return (
    <div className="flex flex-col max-h-screen w-full mx-auto">
      <NavBar/>
      <Outlet />
    </div>
  );
}