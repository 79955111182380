import { Link } from "react-router-dom";
import peakahBirds from "../../assets/peakahBirds.svg";
import NavBarElements from "./NavBarElements";
import NavWallet from "../NavWallet/NavWallet";
import React from "react";

export const NavBar = () => {
  return (
    <nav class="bg-transparent fixed w-full z-20 top-0 left-0">
      <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <Link href="/" class="flex items-center">
          <img src={peakahBirds} class="h-20 w-20 sm:h-24 sm:w-24 left-10 -translate-x-5 sm:translate-x-0" alt="Peaky Birds" />
        </Link>
        <div class="flex md:order-2">
          <NavWallet />
          {/* <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button> */}
        </div>
        {/* Middle section */}
        <div
          class="items-center hidden w-full md:flex md:w-auto md:order-1"
          id="navbar-sticky"
        >
          <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg md:flex-row md:mt-0 gap-4">
            {NavBarElements.map((element, index) => (
              <li>
                <a
                  href={element.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  class="block text-black rounded bg-transparent p-3 border border-gray-400 rounded-lg"
                  aria-current="page"
                >
                  {element.name}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
};
